nav{
    position:relative;
    
}


.hover-effect {
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .hover-effect:hover {
    background-color: var(--bs-primary);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white; 
  }
  
  .hover-effect:hover .card-title, .hover-effect:hover .card-text {
    color: white; 
  
  }

  

.hover-shadow {
    transition: box-shadow 0.3s;
  }
  
  .hover-shadow:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  


  .hover-shadow {
    transition: box-shadow 0.3s;
  }
  
  .hover-shadow:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  









  
  @media (max-width: 767px) {
    .card-group {
      flex-direction: column;
      align-items: center;
    }
    .card {
      width: 90%;
      margin-bottom: 20px;
    }
  }
  
  @media (min-width: 768px) {
    .card-group {
      flex-direction: row;
    }
    .card {
      flex: 1;
      margin: 10px;
    }
  }

  


  .img-fixed-size {
    width: 50px; /* or any desired width */
    height: 50px; /* or any desired height */
    object-fit: cover;
  }
  


  .card-img-top {
    background-color: #f8f9fa;
  }
  





  .navbar-container {
    margin-top: 20px; 
  }
  
  
  .app-download-link {
    position: fixed;
    top: 25px;
    right: 20px;
    background-color: rgba(255, 255, 255, 0.95); /* Slight background color for visibility */
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    z-index: 1000; /* Ensure it stays on top */
    max-width: 356px; /* Adjust max width for better fit */
    word-wrap: break-word; /* Ensure long text wraps properly */
    border: 1px solid #ddd; /* Optional border for better structure */
  }
  
  .app-download-link h4 {
    margin: 0;
    font-size: 18px; /* Slightly larger for readability */
    color: #333; /* Improve text contrast */
  }
  
  .app-download-link p {
    font-size: 14px;
    margin: 8px 0;
    line-height: 1.4; /* Adjust line height for better readability */
  }
  
  .app-download-link .btn {
    margin-top: 10px;
    display: inline-block; /* Ensure proper spacing */
    width: 100%; /* Make button width responsive */
    text-align: center; /* Center the button text */
  }
  
  .main-content {
    margin-top: 120px; /* Increase space to prevent overlap with the fixed section */
    padding: 20px;
  }
  
  .card {
    margin-top: 20px;
    overflow: hidden; /* Prevent text from being cut off */
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .card-title {
    font-size: 1.75rem; /* Adjust size */
    margin: 0 auto; /* Center alignment */
    text-align: center;
    word-wrap: break-word;
    white-space: normal;
    overflow: visible; /* Prevent cutting */
    padding-top: 20px; /* Added padding to push title down */
  }
  
  @media (max-width: 768px) {
    .card-title {
      font-size: 1.5rem; /* Adjust for smaller screens */
      padding-top: 15px; /* Adjust padding for smaller screens */
    }
  }
  
  
  @media (max-width: 768px) {
    .card-title {
      font-size: 1.5rem; /* Adjust for smaller screens */
    }
  
    .app-download-link {
      max-width: 300px; /* Ensure better fit on smaller screens */
      padding: 12px; /* Reduce padding for smaller screens */
    }
  
    .main-content {
      margin-top: 80px; /* Reduce space for smaller screens */
    }
  }
  
  